import { logoRound as logo } from '../../assets/images/eezylogo';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/common/IconButton';
import { NavigationUserPopover } from './NavigationUserPopover';
import { useSelector } from 'react-redux';
import type { IRootState } from 'reducers';
import { Link } from 'react-router-dom';
import { MobileNavigation } from './MobileNavigation';
import { useNavigationItems } from './useNavigationItems';
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from 'components/ui/sheet';
import { useEffect, useState } from 'react';
import { SiteSearch } from './SiteSearch';
import { MobileSiteSearch } from './MobileSiteSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Navigation = () => {
    const { t } = useTranslation();
    const { navigationItems } = useNavigationItems();

    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [sheetView, setSheetView] = useState<'navigation' | 'search'>('navigation');

    // This will allow the navbar to be pressed when the mobile menu is open,
    // Radix UI sets pointer-events to none when the sheet is open. We could set modal={false} to the sheet,
    // but then you could scroll freely with it open.
    useEffect(() => {
        if (isMobileMenuOpen) {
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = '';
            }, 0);

            return () => clearTimeout(timer);
        }

        document.body.style.pointerEvents = 'auto';
    }, [isMobileMenuOpen]);

    if (!isLoggedIn) return null;

    return (
        <div className="flex justify-between items-center px-5 h-20">
            <div className="flex items-center">
                <img alt="Eezy" className="size-10 mr-4" src={logo} />

                {navigationItems.map(({ text, path, icon }) => (
                    <Link
                        key={text}
                        to={path}
                        className="hidden lg:flex tg-body-medium h-20 text-gray-800  gap-2 px-4 items-center no-underline hover:bg-gray-100 active:text-violet-800 active:bg-violet-50 duration-200"
                    >
                        <FontAwesomeIcon icon={icon} className="size-4" />
                        <div>{text}</div>
                    </Link>
                ))}
            </div>

            <div className="flex items-center">
                <div className="hidden lg:block">
                    <SiteSearch />
                </div>
                {!isMobileMenuOpen && (
                    <IconButton
                        icon={['far', 'search']}
                        className="lg:hidden"
                        onClick={() => {
                            setSheetView('search');
                            setIsMobileMenuOpen(true);
                        }}
                    />
                )}
                <div className="flex ml-4 items-center">
                    {!isMobileMenuOpen && <IconButton icon={['far', 'bell']} />}
                    <div className="hidden lg:block h-10 w-px bg-gray-200 mr-6 ml-4" />
                    <div className="hidden lg:block">
                        {/* User avatar which triggers dropdown menu */}
                        <NavigationUserPopover />
                    </div>
                    <div className="lg:hidden ml-4">
                        {/* Mobile navigation trigger and component */}
                        <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
                            {!isMobileMenuOpen && (
                                <SheetTrigger asChild>
                                    <IconButton
                                        icon={['far', 'bars']}
                                        onClick={() => setSheetView('navigation')}
                                    />
                                </SheetTrigger>
                            )}
                            {/* TODO: once we change the toaster, the z-index can be changed. Current toaster uses z-index of 9999 */}
                            <SheetContent
                                className="w-full md:max-w-full h-[calc(100%-5rem)] p-0 mt-20 z-[99999]"
                                excludeClose
                                overlayClassName="mt-20"
                                onPointerDownOutside={(e) => e.preventDefault()}
                            >
                                {sheetView === 'navigation' ? (
                                    <>
                                        <SheetTitle className="sr-only">Mobile navigation</SheetTitle>
                                        <MobileNavigation onLinkClick={() => setIsMobileMenuOpen(false)} />
                                    </>
                                ) : (
                                    <MobileSiteSearch onLinkClick={() => setIsMobileMenuOpen(false)} />
                                )}
                            </SheetContent>
                        </Sheet>
                        {isMobileMenuOpen && (
                            <IconButton
                                icon={['far', 'close']}
                                onClick={() => setIsMobileMenuOpen(false)}
                                outline
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
