import { useEffect, useRef, useState } from 'react';
import { formatCents } from 'utils/money';
import { IconButton } from 'components/common/IconButton';
import { cn } from 'utils';
import { CHART_COMPARE_COLOR_TO_TAILWIND_TEXT, type ChartColor } from 'constants/statisticConstants';

// This component has a few boolean flags, due to being used in both RevenueForecastTab and StatisticsTab,
// And them having some UI differences

type StatisticsBillingDetailProps = {
    data: {
        name: string;
        color: string;
        // Affects only non-main data
        ballBorderColor?: string;
        value: number;
    };
    className?: string;
    onRemove?: () => void;
    subtext?: string;
    isMainData?: boolean;
    // Whether to show the remove button, only affects non-main data
    canRemove?: boolean;
    // Affects only non-main data
    large?: boolean;
    // Whether to hide the ball on the left side
    hideBall?: boolean;
    textClassName?: string;
    includeBackgroundOnMobile?: boolean;
};

const StatisticsBillingDetail = ({
    data,
    className,
    subtext,
    onRemove: removePeriod,
    isMainData,
    canRemove,
    large,
    hideBall,
    textClassName,
    includeBackgroundOnMobile,
}: StatisticsBillingDetailProps) => {
    const [sum, setSum] = useState(data.value);

    const animationFrameRef = useRef<number>();

    // This makes the number update smoothly
    const updateVisibleSum = () => {
        setSum((sum) => {
            const diff = data.value - sum;

            if (Math.round(diff) === 0) {
                return data.value;
            }

            const step = diff / 3;

            return sum + step;
        });
        animationFrameRef.current = requestAnimationFrame(updateVisibleSum);
    };

    useEffect(() => {
        if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current);
        updateVisibleSum();

        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [data.value]);

    const textColorClass = CHART_COMPARE_COLOR_TO_TAILWIND_TEXT[data.color as ChartColor] ?? 'text-gray-800';

    return (
        <div
            className={cn(
                'flex items-center gap-4 relative',
                !isMainData && 'flex-1 md:p-0 md:flex-none',
                includeBackgroundOnMobile &&
                    'border border-gray-100 rounded-lg bg-gray-50 md:border-none md:bg-transparent px-5 py-4 md:p-0',
                className,
            )}
        >
            <div>
                <div
                    className={cn(
                        'tg-subtitle mb-2 text-gray-800',
                        large && 'tg-heading-3',
                        isMainData && 'tg-heading-2',
                        !isMainData && textColorClass,
                        textClassName,
                    )}
                >
                    {formatCents(sum * 100, true)} <span className="tg-subtitle">€</span>
                </div>
                <div className="flex gap-3 items-center">
                    {!hideBall && (
                        <div
                            className={cn(
                                'size-[10px] rounded-full border border-transparent',
                                isMainData && 'bg-violet-200 border-violet-500',
                            )}
                            style={{
                                backgroundColor: isMainData ? '' : data.color,
                                borderColor: data.ballBorderColor ?? '',
                            }}
                        />
                    )}
                    <div
                        className={cn(
                            'tg-caption text-gray-600',
                            large && 'tg-caption-medium',
                            textClassName,
                        )}
                    >
                        {subtext ?? data.name}
                    </div>
                </div>
            </div>
            {!isMainData && canRemove && (
                <IconButton
                    icon={['far', 'close']}
                    outline
                    onClick={removePeriod}
                    className="absolute md:sticky top-0 right-0 translate-x-[5px] -translate-y-[5px] md:transform-none"
                />
            )}
        </div>
    );
};

export default StatisticsBillingDetail;
