import { useEffect, useState } from 'react';
import { type ApolloError, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ManualCard } from 'components/cards';
import { Flex } from 'components/Flex';
import { trans } from 'utils';
import { getErrorKey } from 'utils/apolloErrors';
import { getSalaryPaymentOptions } from 'utils/profile/salaryPaymentRules';
import { bankAccountValidator } from 'utils/profile/validators';
import { stripSpaces } from 'utils/str';
import { formatValidationResult } from 'utils/validation';
import { UPDATE_USER_DATA } from './queries';
import { useTranslation } from 'react-i18next';
import Select from 'components/ui/select';
import { Input } from 'components/common/Input';
import { useUserQuery } from 'queries/useUserQuery';

interface IProps {
    defaultOpen?: boolean;
}

export const ProfileSalary = (props: IProps) => {
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [salaryPaymentRule, setSalaryRule] = useState('');
    const [bankAccountError, setBankAccountError] = useState<any>(null);
    const [closeF, setCloseF] = useState<any>(null);

    const { data: user, refetch } = useUserQuery();

    const { t } = useTranslation();

    const hadBankAccount = !!user?.bankAccountNumber;

    const hasForeignIban = (user?.bankAccountNumber && !user?.bankAccountNumber.startsWith('FI')) || false;

    // Local version of update is needed as we want to close the card only if the mutation
    // is completed, and there are currently no other ways to call onCompleted
    const [updateUserData, { loading }] = useMutation(UPDATE_USER_DATA, {
        onCompleted: () => {
            if (closeF) {
                closeF();
            }
            toast(trans('general.saved'));
            handleReset();
            refetch();
        },
        onError: (e: ApolloError) => {
            if ((getErrorKey(e) || '').includes('iban')) {
                toast.error(trans('profile:salary.bankAccount.error2'));
            } else {
                toast.error(trans('errors.general'));
            }
        },
    });

    useEffect(() => {
        if (user?.bankAccountNumber) {
            setBankAccountNumber(user?.bankAccountNumber);
        }
        if (user?.salaryPaymentRuleId) {
            setSalaryRule(user?.salaryPaymentRuleId.toString());
        }
    }, [user]);

    const handleSave = (callback: () => void) => {
        // callback-fn (that closes the card) is available here but needs to be called in
        // onCompleted of the mutation, thus the hack
        setCloseF(() => () => callback());

        const error = hasForeignIban
            ? undefined
            : formatValidationResult(bankAccountValidator.validate(stripSpaces(bankAccountNumber)));
        if (error) {
            setBankAccountError(error);
        } else {
            // no optimistic response, as if the iban is invalid, the update will fail
            // and optimistic response would loose the invalid value in the form
            if (hadBankAccount) {
                updateUserData({
                    variables: {
                        bankAccountNumber: hasForeignIban ? undefined : stripSpaces(bankAccountNumber),
                        salaryPaymentRuleId: Number.parseInt(salaryPaymentRule, 10),
                    },
                });
            } else {
                updateUserData({
                    variables: {
                        bankAccountNumber: hasForeignIban ? undefined : stripSpaces(bankAccountNumber),
                        salaryPaymentRuleId: Number.parseInt(salaryPaymentRule, 10),
                        notifyWhenInvoicePaid: true,
                    },
                });
            }
        }
    };

    const handleReset = () => {
        setBankAccountError(null);
        setBankAccountNumber(user?.bankAccountNumber || '');
        setSalaryRule(user?.salaryPaymentRuleId.toString() || '');
    };

    return (
        <ManualCard
            defaultOpen={props.defaultOpen}
            editableContent={
                <div className="flex flex-col gap-4">
                    <Input
                        disabled={hasForeignIban}
                        error={bankAccountError ? t('profile:salary.bankAccount.error') : undefined}
                        helpText={hasForeignIban ? t('profile:salary.bankAccount.foreign-note') : undefined}
                        label={t('profile:salary.bankAccount.label-edit')}
                        name="bankAccountNumber"
                        placeholder={'FI87 1234 5678 1234 56'}
                        value={bankAccountNumber}
                        onChange={(e) => setBankAccountNumber(e.target.value)}
                        endIconButton={
                            hasForeignIban
                                ? undefined
                                : formatValidationResult(
                                        bankAccountValidator.validate(stripSpaces(bankAccountNumber)),
                                    )
                                  ? ['far', 'circle-exclamation']
                                  : ['far', 'check']
                        }
                    />
                    <div className="flex flex-col gap-2">
                        <div className="tg-caption-bold">{t('profile:salary.salaryPayment.label')}</div>
                        <Select
                            required
                            options={getSalaryPaymentOptions()}
                            triggerText={t('profile:salary.salaryPayment.label')}
                            onChange={setSalaryRule}
                            value={salaryPaymentRule}
                            className="grow"
                        />
                    </div>
                </div>
            }
            onClose={handleReset}
            onSave={handleSave}
            route="salary"
            saveLoading={loading}
            title={t('profile:salary.title')}
            viewContent={
                <Flex column>
                    <p className="tg-body-bold">
                        <b>{t('profile:salary.bankAccount.label')}</b>
                    </p>
                    <p className="tg-body" data-mf-replace="**REMOVED**">
                        {user?.bankAccountNumber}
                    </p>
                    {user?.salaryPaymentRuleId && (
                        <p className="tg-body mt-4">
                            {t(`profile:salary.payments.${user?.salaryPaymentRuleId}`)}
                        </p>
                    )}
                </Flex>
            }
        />
    );
};
