import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useChangeUserLanguageMutation } from 'queries/useUserQuery';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type NavigationItem = {
    text: string;
    path: string;
    icon: IconProp;
};

export type UserDropdownMenuItem = Omit<NavigationItem, 'path'> & {
    path?: string;
    subItems?: Omit<UserDropdownMenuItem, 'Icon'>[];
    onClick?: () => void;
};

export const useNavigationItems = () => {
    const { t, i18n } = useTranslation();
    const changeUserLanguage = useChangeUserLanguageMutation();

    const language = useMemo(() => {
        switch (i18n.language) {
            case 'en':
                return 'English';
            case 'fi':
                return 'Suomi';
        }
    }, [i18n.language]);

    const navigationItems: NavigationItem[] = [
        {
            text: t('nav.invoices'),
            path: '/invoices',
            icon: ['far', 'file-invoice'],
        },
        {
            text: t('nav.eezyPay'),
            path: '/sales-pages',
            icon: ['far', 'mobile'],
        },
        {
            text: t('nav.salaries'),
            path: '/salaries',
            icon: ['far', 'wallet'],
        },
        {
            text: t('nav.statistics'),
            path: '/statistics',
            icon: ['far', 'chart-mixed'],
        },
    ];

    // Dropdown menu items grouped by sections
    const dropdownMenuItems: UserDropdownMenuItem[][] = [
        [
            {
                text: t('nav.own-info'),
                path: '/profile',
                icon: ['far', 'cog'],
            },
            {
                text: `${t('nav.language')} - ${language}`,
                icon: ['far', 'globe'],
                subItems: [
                    {
                        text: 'English',
                        onClick: () => changeUserLanguage('en'),
                    },
                    {
                        text: 'Suomi',
                        onClick: () => changeUserLanguage('fi'),
                    },
                ],
            },
        ],
        [
            {
                text: t('nav.salary-calculator'),
                path: '/salary-calculator',
                icon: ['far', 'calculator'],
            },
            {
                text: t('nav.documents'),
                path: '/profile/documents',
                icon: ['far', 'folder'],
            },
        ],
        [
            {
                text: t('nav.customer-service'),
                path: '/support',
                icon: ['far', 'headset'],
            },
            {
                text: t('nav.feedback'),
                icon: ['far', 'message-lines'],
            },
        ],
    ];

    return {
        navigationItems,
        dropdownMenuItems,
    };
};
