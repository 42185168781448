import type { FormEvent, KeyboardEvent } from 'react';
import { Input } from './common/Input';
import ToolTip from 'components/ui/tooltip';

interface ISearchFieldProps {
    onChange: (query: string, fireImmediately?: boolean) => void;
    placeholder: string;
    query: string;
    tooltip?: string;
}

const SearchField = (props: ISearchFieldProps) => {
    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        props.onChange(e.currentTarget.value);
    };

    const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        const code = e.key;
        if (code === 'Enter') {
            // enter pressed, fire search immediately
            props.onChange(e.currentTarget.value, true);
        }
    };

    const handleEmpty = () => {
        props.onChange('', true);
    };

    const renderSearchField = () => (
        <Input
            onChange={handleChange}
            onKeyDown={handleEnter}
            placeholder={props.placeholder}
            type="text"
            value={props.query}
            startIcon={['far', 'search']}
            endIconButton={props.query ? ['far', 'close'] : undefined}
            onEndIconButtonClick={handleEmpty}
            helpText={props.tooltip}
        />
    );

    if (!props.tooltip) {
        return renderSearchField();
    }

    return (
        <ToolTip trigger={renderSearchField()}>
            <p>{props.tooltip}</p>
        </ToolTip>
    );
};

export default SearchField;
